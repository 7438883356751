import React from 'react'
import { Helmet } from 'react-helmet'
import freePickup from '../assets/images/free-home-pickup-service.jpg'
import medicineDelivery from '../assets/images/medicine-delivery-service-hyderabad.jpeg'
import trackParcel from '../assets/images/parcel-tracking-online.jpeg'

export const Home = () => {
  return (
    <main>
      <Helmet>
        <title>
          International Courier Services from Hyderabad to World, Call +91
          8341047781
        </title>
        <meta
          property='og:title'
          content='International Courier Services in Hyderabad'
        />
        <meta
          property='description'
          content='Fastest courier service offers some of the lowest
          prices for international parcel shipping and freight shipping, If you
          are looking for reasonable price and reliable courier services, you can
          connect with us to get best
          offer'
        />
        <meta
          property='og:description'
          content='Fastest courier service offers some of the lowest
          prices for international parcel shipping and freight shipping, If you
          are looking for reasonable price and reliable courier services, you can
          connect with us to get best
          offer'
        />
        <link rel='canonical' href='about-us' />
      </Helmet>
      <h1>International Courier Services from Hyderabad to Abroad</h1>

      <section className='courier-services'>
        <article>
          <figure>
            <img src={freePickup} alt='free home delivery services' />
          </figure>
          <div className='content'>
            <h2>Free home pickup service</h2>
            <p>
              Our executive will come and pickup your parcel located anywhere in
              the city. Packing will be done at pickup location for your
              satisfaction
            </p>
          </div>
        </article>
        <article>
          <figure>
            <img
              src={medicineDelivery}
              alt='international medicine courier service'
            />
          </figure>
          <div className='content'>
            <h2>Medicine delivery service</h2>
            <p>
              Medicine shipping to anywhere in the world. You can provide us
              medicine or if you send the prescription we will buy the medicine
              and deliver it
            </p>
          </div>
        </article>
        <article>
          <figure>
            <img src={trackParcel} alt='track your parcel online' />
          </figure>
          <div className='content'>
            <h2>Track your parcel</h2>
            <p>
              You can track your parcel status and expected time to deliver with
              the tracking id which will be provided to you once your parcel is
              connected
            </p>
          </div>
        </article>
      </section>
      <section>
        <h2>We take care of your parcel from pickup to delivery</h2>
        <p>
          <strong>Fastest courier service</strong> offers some of the lowest
          prices for international parcel shipping air courier service from
          Hyderabad to Abroad. If you are looking for reasonable price and
          reliable courier services, you can connect with us to get best offer
          courier services for very low price comparing to other courier
          services in Hyderabad. Out of many international parcel services in
          Hyderabad, we have been successful in providing best door-to-door
          courier services from India to various parts of the world. Depending
          on the size and weight of your parcel, your parcel may be collected by
          either motorbike or small van. Our executives will assist you
          throughout the whole process
        </p>
        <h2>Courier services to UK from India</h2>
        <p>
          We provide international parcel services from India to UK, you don’t
          have to worry about your parcel delivery needs, we are the best
          overseas courier providers in Hyderabad. If your business require
          speedy parcel services form India to UK, then we are here to help you
          grow your business by providing you efficient parcel services with
          ensuring quick pickup and fast delivery service as soon as possible.
          We also provide important document parcel service from India to UK. We
          have premium , express and economy courier services for you to choose
          according to your convenience
        </p>
        <h2>Courier services to USA from India</h2>
        <p>
          Want to send some important documents to the United States ?. We have
          been providing reliable and fast courier service that handled
          deliveries to send the documents to the USA from India. Give us a try
          to send the documents over to the USA, we guarantee and take extra
          care to ensure that the documents were properly packaged and addressed
          and would arrive safely and delivered within the specified time
        </p>
        <p>
          Send food items to USA, electronic items, spices, medicines, excess
          baggage, books, stationary items, condiments, clothing, pickles,
          artificial jewelry, precious and other necessary items through our
          excellent courier services to USA. Your delivery of packages from
          India to the USA would be delivered swiftly and securely through our
          reliable courier service to United States of America. Once your
          package connected we will update you the tracking number and also our
          executive will call you and update you when delivered to its
          destination, we thank our customers profusely for choosing us to send
          parcel delivery to USA
        </p>
        {/* 
        <h2>Courier services to Australia from India</h2>
        
        <h2>Courier services to Germany from India</h2>
        <h2>Courier services to Italy from India</h2>
        <h2>Courier services to Norway from India</h2>
        <h2>Courier services to France from India</h2>
        <h2>Courier services to Netherlands from India</h2>
        <h2>Courier services to Poland from India</h2>
        <h2>Courier services to Singapore from India</h2>
        <h2>Courier services to Malaysia from India</h2>
        <h2>Courier services to Oman from India</h2> */}
        <h2>Courier services to Canada from India</h2>
        <p>
          Are you looking for the best shipping services from India to Canada?
          Look no further - Fastest courier is here to provide you with the best
          and most reliable courier services from India to Canada. you can send
          a courier to Canada from India with ease. Our shipping services from
          India to Canada are designed to provide you with a hassle-free
          experience.
        </p>
        <p>
          We also offer the most competitive prices when it comes to shipping
          items to Canada. If you are looking for the best and cheapest courier
          service from India to Canada, look no further than Fastest Courier. We
          provide the best delivery services from India to Canada, taking care
          of all your delivery needs. We also offer courier services to Canada
          from Hyderabad. With Fastest Courier, you can send a courier to Canada
          from Hyderabad with ease.
        </p>
        <p>
          We provide delivery services to Canada from Hyderabad. We provide the
          best delivery services from India to Canada, taking care of all your
          delivery needs. Whether you are sending documents, parcels or any
          other items, we guarantee that your items will reach their destination
          safely and on time.
        </p>
        <p>
          For those looking to send food items from Hyderabad to Canada. Fast
          Courier is the perfect solution for you. With Courier, you can easily
          send a courier to Canada from India.If you are based in Hyderabad and
          want to send a courier to Canada from India, our delivery services are
          the perfect choice. Whether you’re looking for the best shipping
          services from India to Canada or the cheapest courier service from
          India to Canada. We’re the leading provider of delivery services to
          Canada from India
        </p>
        <h2>Courier services to Dubai from India</h2>
        <p>
          Are you looking for a reliable courier service to get your documents
          and parcels to Dubai from India? Courier is the perfect option for
          you. With our world-class courier services, we ensure that your
          shipments reach their destination safely, securely, and on time. we
          understand the importance of reliable and efficient delivery services.
          We take great pride in providing our customers with top-notch courier
          services to Dubai from India. Our team of dedicated professionals is
          equipped with the expertise, resources, and experience to handle any
          size shipment or parcel. We are committed to serve you Best shipping
          services from India to UAE delivering your goods with speed and
          accuracy.
        </p>
        <p>
          When you choose to send a courier to UAE From India for your shipping
          needs, you can rest assured that your shipments will be delivered with
          the utmost care. Our NRI courier services from India to UAE are
          designed to exceed all your expectations and guarantee safe and secure
          delivery. We use advanced tracking systems and state-of-the-art
          technology to ensure that your shipments are tracked and monitored at
          every step of the journey.
        </p>
        <p>
          What’s more, our cheap courier to UAE from India are cost-effective
          and super-fast. So, don’t hesitate to get in touch with us today for
          all your courier service queries like Courier to Uae from India
          Charges and more. We offer reliable and fast shipping services to
          Dubai, Sharjah, and Abu Dhabi. We look forward to hearing from you
          soon!
        </p>

        <h2>Track your shipment</h2>
        <p>
          Every logistics service providers offer a possibility to track each
          shipment that is transported. Every parcel has a unique barcode which
          is repeatedly scanned at all transit locations, which allows you to
          trace the journey of your packages. For every package you send through
          fast courier service, you will receive in your confirmation e-mail a
          tracking number. Its purpose is to track your shipment status online
          until it arrives the destination.
        </p>
        <p>
          Our system will determine the most suitable form of transport for your
          item depending on size and weight, so you can be sure you are always
          offered the most cost effective option.
        </p>
        <h2>Delivery considerations for courier services </h2>
        <p>
          Name on the doorbell – If the recipient’s name is not displayed on the
          doorbell, it might cause confusion.
        </p>
        <p>
          Please provide us accurate details about the shipment – Any mistakes
          can lead to delays and unsuccessful deliveries.
        </p>
        <h2>Our logistics partners:</h2>
        <p>
          Logistics partners are known for their reliability and their best
          services. our team carefully chooses partners that offer the best
          price to performance ratio while also monitoring their performance. In
          this way, we do not only make sure your shipments are in safe hands,
          but also that they are delivered in time and provide international
          fast courier service.
        </p>
      </section>
    </main>
  )
}
