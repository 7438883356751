import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className='footer-container'>
      <div className='footer-content'>
        <div className='footer-col'>
          <h2>Fastest international Courier and cargo</h2>
          <address>
            16-4-576
            <br />
            Beside MBT office, Fateh khan bazaar,
            <br />
            Officers colony <br />
            chanchalguda, Hyderabad - 500024,
            <br /> Telangana state, India
          </address>
        </div>
        <div className='footer-col'>
          <h2>Links</h2>
          <nav className='footer-navigation'>
            <NavLink to='/'>Home</NavLink>
            <NavLink to='/about-us'>About us</NavLink>
            <NavLink to='/services'>Services</NavLink>
            <NavLink to='/contact'>Contact</NavLink>
            <NavLink to='/medicine-delivery'>Medicine delivery</NavLink>
          </nav>
        </div>
        <div className='footer-col'>
          <h2>Contact Us</h2>
          <ul className='footer-list'>
            <li>
              <a href='tel:+918341047781'>+91 8341047781</a>
            </li>
            {/* <li>
              <a href='tel:+919542179291'>+91 9542179291</a>
            </li> */}
            <li>
              <a href='mailto:fastestcouriercargointl@gmail.com'>
                fastestcouriercargointl@gmail.com
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='footer-content'>
        <small>
          &copy; Fastest international Courier and cargo Services
          <time dateTime={new Date().getFullYear()}>
            {new Date().getFullYear()}
          </time>
          . All rights reserved
        </small>
      </div>
    </footer>
  )
}

export default Footer
