import React from 'react'
import { Helmet } from 'react-helmet'

export const Contact = () => {
  return (
    <main>
      <Helmet>
        <title>Send parcel international cheap, call +91 8341047781</title>
        <meta
          property='og:title'
          content='Send parcel international cheap, call +91 8125911887'
        />
        <meta
          property='description'
          content='dhl international courier services from Hyderabad, door to door quick pickup and delivery service, contact us for more info'
        />
        <meta
          property='og:description'
          content='dhl international courier services from Hyderabad, door to door quick pickup and delivery service, contact us for more info'
        />
        <link rel='canonical' href='contact' />
      </Helmet>
      <h1>Contact us</h1>
      <div className='contact-info'>
        <div>
          <h4 className='pre-title'>Email us at</h4>
          <p>
            <a href='mailto:fastestcouriercargointl@gmail.com'>
              fastestcouriercargointl@gmail.com
            </a>
          </p>
        </div>
        <div>
          <h4 className='pre-title'>Call us at</h4>
          <p>
            <a href='https://api.whatsapp.com/send?phone=+918341047781'>
              +91- 8341047781
            </a>
          </p>
        </div>
        <div>
          <h4>Find us at</h4>

          <p>
            Fastest international Courier and cargo
            <br /> 16-4-576
            <br />
            Beside MBT office, Fateh khan bazaar,
            <br />
            Officers colony <br />
            chanchalguda, Hyderabad - 500024,
            <br /> Telangana state, India
          </p>
        </div>
      </div>
    </main>
  )
}
